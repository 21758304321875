.modal-overlay {
  position: absolute;
  z-index: 30;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
}

.modal-header {
  padding: 2px 16px;
  background-color: var(--app-color);
  color: white;
  display: grid;
  height: 50px;
  grid-template-columns: 95% 5%;
  h3 {
    margin: 0;
  }
  div {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  div:nth-child(2) {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    span {
      cursor: pointer;
      font-size: 30px;
    }
  }
}

/* Modal Body */
.modal-body {
  width: 100%;
}

/* Modal Content */
.modal-content {
  position: absolute;
  z-index: 33;
  left: 0;
  top: 5%;
  background-color: #fefefe;
  margin: auto;
  padding: 0;
  border: 1px solid #888;
  width: 60%;
  margin-left: 20%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  animation: animatetop 0.7s ease-in-out;
  overflow-y: auto;
  overflow-x: auto;
  max-height: 90%;
  border-radius: 20px;
}

/* Add Animation */
@keyframes animatetop {
  from {
    top: -10%;
    opacity: 0;
  }
  to {
    top: 5%;
    opacity: 1;
  }
}
