.AuthWrapper
{
    height: 100vh;
    display: grid;
    grid-template-columns: 50% 50%;
	.leftContent
    {
        height: 100%;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        color: black;
        background-color: rgba(240, 240, 240, 0.692);
        img
        {
            width: 400px;
            height: 400px;
        }
        h2, h5
        {
            margin-top: 0;
            margin-bottom: 10px;
        }
    }
    .rightContent
    {
        padding-left: 30px;
        padding-right: 30px;
        height: 100%;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: flex-start;
        h1
        {
            margin: 0;
        }
        h4
        {
            color: rgb(134, 134, 134);
        }
        .forgottenPassword
        {
            margin-top: 20px;
            color: var(--app-color);
        }
    }
}