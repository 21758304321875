.WelcomeWrapper {
  height: 100vh;
  width: 100%;
  display: grid;
  grid-template-columns: 20% 80%;
  @media (min-width: 1380px) {
    grid-template-columns: 18% 82%;
  }
  .logo {
    width: 80%;
    margin-left: 10%;
    height: 100px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    font-size: 30px;
    color: white;
    img {
      width: 45px;
      height: 45px;
      cursor: pointer;
    }

    .name {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
    }
  }
  .toolset {
    background-color: rgb(153, 153, 153);
    overflow-x: hidden;
    overflow-y: auto;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    .groupeName-title {
      color: var(--app-text-reversed);
      padding-left: 15px;
      margin-top: 15px;
      font-weight: bold;
      font-size: 15px;
      border-bottom: solid 1px rgb(175, 175, 175);
      width: calc(100% - 30px);
      margin-left: 15px;
      margin-bottom: 10px;
      padding: 5px 0 15px 0;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;

      svg {
        margin-left: 5px;
        width: 20px;
        height: 20px;
        path {
          fill: white;
        }
      }
    }
    .groupeMenu-0 {
      margin-top: 0px;
    }
    .menuItem {
      padding: 0 15px;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      cursor: pointer;
      height: 45px;
      margin-top: 5px !important;
      transition: all 0.5s;
      .icon {
        svg {
          width: 25px;
          height: 25px;
          margin-left: 5px;
          path {
            fill: white;
          }
        }
      }
      .title {
        margin-left: 5px;
        color: white;
        font-weight: bold;
        font-size: 13px;
        @media (max-width: 992px) {
          text-align: center;
        }
      }
      &:hover {
        background-color: white;
        border-top-left-radius: 55px;
        border-bottom-left-radius: 55px;
        color: black;
        .title {
          margin-left: 5px;
          color: black;
          font-weight: bold;
          font-size: 13px;
          @media (max-width: 992px) {
            text-align: center;
          }
        }
        svg {
          path {
            fill: black;
          }
        }
      }
    }
    .activeMenuItem {
      background-color: white;
      border-top-left-radius: 55px;
      border-bottom-left-radius: 55px;
      color: black;
      .title {
        margin-left: 5px;
        color: black;
        font-weight: bold;
        font-size: 13px;
        @media (max-width: 992px) {
          text-align: center;
        }
      }
      .icon {
        svg {
          path {
            fill: black !important;
          }
        }
      }
    }
  }
  .content {
    .outlet {
      height: 92vh !important;
      overflow-y: auto;
      overflow-x: hidden;
    }
  }
}
