.HomeWrapper
{
    padding: 0 20px;
    .content
    {
        width: 100%;
        margin-top: 40px;
        .someGraphs
        {
            display: flex;
            flex-flow: row wrap;
            justify-content: space-between;
            .table
            {
                width: 100%;
            }
            .barchart
            {
                width: 45%;
            }
        }
        .pies
        {
            .content
            {
                display: flex;
                flex-flow: column;
                width: 70%;
                margin-left: 15%;
            }
        }
    }
}