.StatRecapWrapper
{
	width: 100%;
    display: flex;
    flex-flow: row wrap;
    margin-bottom: 20px;
    .item
    {
        width: 180px;
        height: 150px;
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;
        background-color: white;
        border-radius: 15px;
        margin: 15px 15px 15px 0;
        -webkit-box-shadow: 0px 0px 15px 5px rgba(0,0,0,0.09); 
        box-shadow: 0px 0px 15px 5px rgba(0,0,0,0.09);
        .icon
        {
            width: 45px;
            height: 45px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: var(--app-color);
            margin-bottom: 10px;
            svg
            {
                width: 25px;
                height: 25px;
                path
                {
                    fill: white;
                }
            }
        }
    }
    .title
    {
        font-weight: bold;
        font-size: 19px;
        margin-bottom: 5px;
    }
    .value
    {
        font-weight: normal;
        font-size: 17px;
    }
}