.FormComponentWrapper {
  width: 100%;

  p[role="alert"] {
    margin-top: 7px;
  }
  .goBack {
    display: flex;
    justify-content: flex-start;
  }
  .container {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--app-color);
    margin-bottom: 10px;
    cursor: pointer;
    svg {
      width: 27px;
      height: 27px;
      path {
        fill: white;
      }
    }
  }
  .formContent {
    margin-top: 30px;
    form {
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-start;
      h4 {
        margin: 0;
        //position: absolute;
        //transform: translateY(-78px);
        font-weight: normal;
      }
      .submitDiv {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 20px;
      }

      .formInput {
        width: 48%;
        margin-bottom: 50px;
        margin-left: 5%;
        height: 60px;
        display: flex;
        flex-flow: column;
        justify-content: center;
      }
      .textArea {
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        width: 100% !important;
        height: fit-content;
        margin-top: 20px;
        h4 {
          transform: translateY(5px);
        }
        textarea {
          width: 90vh;
          margin-left: 1%;
          margin-top: 10px;
          min-height: 100px;
          padding: 10px;
        }
      }
      .tt input {
        height: 55px;
        transform: translateY(6px);
      }
      .tt textarea {
        margin-top: 10px;
      }
      .tt h4 {
        margin-bottom: 4px;
      }
    }
    .custom-select {
      margin-top: 10px;
      width: 105.5%;
    }
  }
}
