.NotFoundWrapper
{
	width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    img {
        width: 200px;
        height: 200px;
    }
}