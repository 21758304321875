.BreadCumbWrapper {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  svg {
    width: 24px;
    height: 24px;
    margin-left: 5px;
    path {
      fill: black;
    }
  }
  .container {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    width: fit-content;
    .item {
      margin-right: 10px;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
    }
  }
}
