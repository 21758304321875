.SeeDataWrapper
{
    overflow: hidden !important;
	.alertBackdrop
    {
        position: absolute;
        z-index: 99999999999999999999999999999999999;
        background-color: rgb(0, 0, 0, 0.5);        
        min-height: 100vh !important;
        width: 100%;
        top: 0px;
        left: 0px;
    }

    .modal
    {
        position: absolute;
        z-index: 999999999999;
        width: 100%;
        min-height: 100vh !important;
        border-radius: 30px;
        padding-bottom: 30px;
        top: -10%;
        left: 0px;
        height: 500px;
        display: flex;
        flex-flow: column;
        justify-content: center;
        width: 95%;
        margin-left: 2.5%;
        @media (min-width: 768px) {
            width: 60%;
            margin-left: 20%;
        }
        @media (min-width: 992px) {
            width: 40%;
            margin-left: 30%;
        }

        
        .close
        {
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
            height: 50px;
            margin-bottom: 10px;
            svg
            {
                background-color: white;
                border-radius: 50%;
                cursor: pointer;
                width: 30px;
                height: 30px;
            }
        }

        .content
        {
            background-color: white;
            border-radius: 50px;
            overflow-y: auto;
            padding: 0 40px;
            display: flex;
            flex-flow: column;
            margin-top: 0px;
            h2
            {
                text-align: center;
                width: 100% !important;
            }
            h3
            {
                text-align: center;
                color: grey;
                font-weight: bold;
                margin-bottom: 0px;
            }
            .displayData
            {
                display: flex;
                flex-flow: row wrap;
                justify-content: space-around;
                .item
                {
                    margin-top: 20px;
                    display: flex;
                    flex-flow: column;
                    .title
                    {
                        font-weight: bold;
                        font-size: 17px;
                    }
                    .value
                    {
                        font-size: 20px;
                    }
                }
            }
        }
    }

    .infosCard {
        margin-top: 20px;
        min-height: 200px;
        border-radius: 20px;
        border: solid 1px grey;
        .header {
            background-color: var(--app-color);
            color: white;
            padding: 10px 15px;
            border-top-right-radius: 20px;
            border-top-left-radius: 20px;
            height: 40px;
            display: flex;
            align-items: center;
            font-size: 18px;
            font-weight: bold;
        }
        .body {
            background-color: white;
            color: black;
            display: flex;
            flex-flow: column;
            justify-content: space-around;
            border-bottom-right-radius: 20px;
            border-bottom-left-radius: 20px;
            padding: 10px 20px;
            .item
            {
                display: flex;
                flex-flow: row nowrap;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 15px;
                .title
                {
                    font-weight: bold;
                    font-size: 17px;
                }
                .value
                {
                    font-size: 20px;
                }
            }
        }
    }

    .goBack
    {
        display: flex;
        justify-content: flex-start;
        .container
        {
            width: 25px !important;
            height: 25px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: var(--app-color);
            margin-bottom: 10px;
            cursor: pointer;
            svg
            {
                width: 27px;
                height: 27px;
                path
                {
                    fill: white;
                }
            }
        }
    }
}