:root {
  --app-color: #cfae1f;
  --danger-color: #ff4d4d;
  --success-color: rgb(8, 250, 8);
  --border-color: rgba(196, 196, 196, 0.87);

  //

  --app-text: black;
  --app-text-reversed: white;
}

.menu-with-tabs {
  height: 92vh;
  .subMenu {
    height: 10%;
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    .item {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      height: 50px;
      font-weight: bold;
      font-size: 20px;
      min-width: 175px;
      cursor: pointer;
      svg {
        width: 25px;
        height: 25px;
        cursor: pointer;
        margin-right: 5px;
        path {
          fill: black;
        }
      }
    }
    .choosenItem {
      border-bottom: 3px solid var(--app-color);
      color: var(--app-color);
      svg {
        path {
          fill: var(--app-color);
        }
      }
    }
  }
  .sub-outlet {
    height: 90%;
    overflow-y: scroll !important;
  }
}

.custom-wrapper {
  width: 92%;
  margin-left: 4%;
  margin-bottom: 4%;
  margin-top: 3%;
}

.customInput {
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  min-width: 300px;
  max-width: 400px;
  p {
    margin: 0;
    color: rgb(255, 72, 72);
  }
  .title {
    width: 100%;
    font-size: 16px;
    margin-bottom: 5px;
  }
  .input {
    width: 100%;
    input {
      width: 100%;
      height: 45px;
      font-size: 16px;
      border: solid 1px rgba(196, 196, 196, 0.87);
      outline: none;
      padding: 0 10px;
      border-radius: 5px;
    }
    select {
      width: 100%;
      height: 45px;
      font-size: 16px;
      border: solid 1px rgba(196, 196, 196, 0.87);
      outline: none;
      padding: 0 10px;
      border-radius: 5px;
    }
  }
  .eye {
    width: auto;
    position: relative;
    left: 110%;
    bottom: 35%;
    cursor: pointer;
    svg {
      width: 30px;
      height: 30px;
    }
  }
}

.app-btn {
  background-color: var(--app-color);
  color: white;
  font-weight: bold;
  font-size: 19px;
  border: unset;
  border-radius: 5px;
  min-height: 50px;
  width: 200px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 24px;
    height: 24px;
    margin-left: 5px;
    path {
      fill: white;
    }
  }
}

.app-btn-2 {
  background-color: var(--app-color);
  color: white;
  font-weight: bold;
  font-size: 17px;
  border: unset;
  border-radius: 5px;
  height: 45px;
  width: 100px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 24px;
    height: 24px;
    margin-left: 5px;
    path {
      fill: white;
    }
  }
}

.spacer {
  height: 30px;
}

.ToastWrapper {
  position: absolute;
  bottom: 5%;
  left: 0;
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  .messageContent {
    text-align: center;
    width: fit-content;
    padding: 20px 25px;
    background-color: var(--app-color);
    color: white;
    font-weight: bold;
    border-radius: 10px;
    margin-right: 3%;
  }
}

.LoadingWrapper {
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 99;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .lds-spinner {
    color: official;
    display: inline-block;
    position: relative;
    width: 80px !important;
    height: 80px !important;
  }
  .lds-spinner div {
    transform-origin: 40px 40px;
    animation: lds-spinner 1.2s linear infinite;
  }
  .lds-spinner div:after {
    content: " ";
    display: block;
    position: absolute;
    top: 3px;
    left: 37px;
    width: 6px;
    height: 18px;
    border-radius: 20%;
    background: var(--app-color);
  }
  .lds-spinner div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -1.1s;
  }
  .lds-spinner div:nth-child(2) {
    transform: rotate(30deg);
    animation-delay: -1s;
  }
  .lds-spinner div:nth-child(3) {
    transform: rotate(60deg);
    animation-delay: -0.9s;
  }
  .lds-spinner div:nth-child(4) {
    transform: rotate(90deg);
    animation-delay: -0.8s;
  }
  .lds-spinner div:nth-child(5) {
    transform: rotate(120deg);
    animation-delay: -0.7s;
  }
  .lds-spinner div:nth-child(6) {
    transform: rotate(150deg);
    animation-delay: -0.6s;
  }
  .lds-spinner div:nth-child(7) {
    transform: rotate(180deg);
    animation-delay: -0.5s;
  }
  .lds-spinner div:nth-child(8) {
    transform: rotate(210deg);
    animation-delay: -0.4s;
  }
  .lds-spinner div:nth-child(9) {
    transform: rotate(240deg);
    animation-delay: -0.3s;
  }
  .lds-spinner div:nth-child(10) {
    transform: rotate(270deg);
    animation-delay: -0.2s;
  }
  .lds-spinner div:nth-child(11) {
    transform: rotate(300deg);
    animation-delay: -0.1s;
  }
  .lds-spinner div:nth-child(12) {
    transform: rotate(330deg);
    animation-delay: 0s;
  }
  @keyframes lds-spinner {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
}

.GeneralLoadingWrapper {
  .lds-hourglass {
    color: var(--app-color);
  }
  .lds-hourglass:after {
    box-sizing: border-box;
  }
  .lds-hourglass {
    position: absolute;
    top: 0;
    left: 0;
    background-color: white;
    z-index: 110;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .lds-hourglass:after {
    content: " ";
    display: block;
    border-radius: 50%;
    width: 0;
    height: 0;
    margin: 8px;
    box-sizing: border-box;
    border: 32px solid currentColor;
    border-color: currentColor transparent currentColor transparent;
    animation: lds-hourglass 1.2s infinite;
  }
  @keyframes lds-hourglass {
    0% {
      transform: rotate(0);
      animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
    50% {
      transform: rotate(900deg);
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    100% {
      transform: rotate(1800deg);
    }
  }
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
