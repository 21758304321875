.UploadComponentWrapper {
  padding: 0px 20px 10px;
  .options {
    display: flex;
    flex-flow: column;
    .opt {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-evenly;
      align-items: center;
    }
  }

  h2,
  h3 {
    text-align: center;
  }
  h3 {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--danger-color);
    svg {
      width: 20px;
      height: 20px;
      margin-right: 10px;
      path {
        fill: var(--danger-color);
      }
    }
  }

  .goBack {
    display: flex;
    justify-content: flex-start;
  }
  .container {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--app-color);
    margin-bottom: 10px;
    cursor: pointer;
    svg {
      width: 27px;
      height: 27px;
      path {
        fill: white;
      }
    }
  }
}
