.TableComponentWrapperOptions {
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  button {
    width: 30px;
    height: 30px;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--app-color);
    border: unset;
    margin-right: 10px;
    cursor: pointer;
    svg {
      width: 20px;
      height: 20px;
      path {
        fill: white;
      }
    }
  }

  input {
    width: 0%;
    margin-right: 10px;
    height: 35px;
    font-size: 17px;
    border: solid 1px var(--border-color);
    outline: none;
    padding: 0 10px;
    border-radius: 5px;
    transition: all 1.5s;
  }
}

.TableComponentWrapper {
  .tableContainer {
    border: solid 1px var(--border-color);
    border-radius: 5px;
    padding: 0 30px 10px 30px;
    overflow-x: auto !important;
  }

  table {
    width: 100%;
    width: 100%;
    padding: 0px 20px;
    border-collapse: collapse;
    overflow-x: auto !important;
    tr {
      height: 55px;
      padding: 0 !important;
    }

    th {
      text-align: left;
      font-size: 17px;
      height: 55px;
      padding: 0 7px !important;

      svg {
        width: 30px;
        height: 30px;
        path {
          fill: black;
        }
        cursor: pointer;
      }
    }
    td {
      height: 55px;
      padding: 0 7px !important;
      text-align: left;
      font-size: 17px;
      border-top: solid 1px var(--border-color);
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      svg {
        width: 24px;
        height: 24px;
        margin-left: 5px;
        path {
          fill: rgb(146, 146, 146);
        }
        cursor: pointer;
      }
      .view {
        margin: 0 5px;
        cursor: pointer;
        path {
          fill: black;
        }
      }
      .success {
        margin: 0 5px;
        cursor: pointer;
        path {
          fill: var(--success-color);
        }
      }
      .danger {
        margin: 0 5px;
        cursor: pointer;
        path {
          fill: var(--danger-color);
        }
      }
    }
  }
  .divider {
    display: grid;
    grid-template-columns: 50% 50%;
    .numberElements {
      text-align: left;
      font-size: 17px;
      margin-top: 15px;
      span {
        font-weight: bold;
      }
    }
    .navigation {
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-end;
      align-items: center;
      margin-top: 15px;
      div {
        margin: 0 2px;
        font-size: 20px;
        cursor: pointer;
        svg {
          width: 25px;
          height: 25px;
        }
      }
      .current {
        transform: translateY(-4px);
        border: solid 2px black;
        width: 25px;
        text-align: center;
        border-radius: 50%;
      }
    }
  }
}

.searchAnimation {
  animation: searchAnimation forwards 1s;
}

@keyframes searchAnimation {
  0% {
    width: 0%;
  }
  100% {
    width: 30%;
  }
}
