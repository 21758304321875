.MenuHeaderWrapper {
  height: 8vh;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-end;
  -webkit-box-shadow: 0px 0px 15px -3px rgba(0, 0, 0, 0.13);
  box-shadow: 0px 0px 15px -3px rgba(0, 0, 0, 0.13);
  svg {
    width: 33px;
    height: 33px;
    cursor: pointer;
    path {
      fill: rgb(114, 114, 114);
    }
  }

  .passwordUpdateModalAvatar {
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      width: 100px;
      height: 100px;
    }
  }
}
